@import "mixins";

/*		   Login
 *===============================*/
#page {
  min-height: inherit; }

#loginPage {
  background: #fff; }

.loginPage {
  margin: 0 auto;
  .whiteBox {
    margin: 4% auto auto auto;
    border: 1px solid #d9d9d9;
    width: fit-content; }
  &__loginPanel {
    &--show {
      display: block; }
    &--hide {
      display: none; } }
  &__loginDetails {
    float: left;
    border-right: 1px solid #ccc;
    width: 350px;
    padding: 20px 0;
    height: 540px;
    position: relative; }
  &__iframeImage {
    float: left;
    width: 500px;
    padding: 0;
    height: 580px; }
  .innerBox {
    margin: 0 20px;
    padding-top: 30px;
    &__formContent {
      margin: 25px 20px 0 20px; } }
  &__btnLogin {
    width: 100%;
    height: 35px!important;
    margin-top: 10px!important;
    &__sso {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-weight: 500; } }
  .padding {
    padding: 10px; }
  .error {
    display: inline-block;
    border: none;
    &__content {
      margin-left: 30px;
      margin-top: -13px; } }
  &__licenseInfo {
    position: absolute;
    margin-bottom: 10px;
    left: 0;
    bottom: 0;
    padding: 0 5px;
    .licenseText {
      font-size: .9em;
      color: #999;
      border-left: 1px solid #999;
      padding-left: 8px;
      float: right; }
    img {
      margin-top: 17px; } }
  .company-logo {
    text-align: center;
    padding: 0 10px;
    min-height: 100px;
    img {
      max-width: 250px;
      max-height: 100px!important;
      margin-bottom: 20px; } }
  //inputs with labels inside
  &__formInput {
    position: relative;
    color: #4c4c4c;
    margin-top: 5px!important;
    margin-bottom: 10px;
    border-radius: 0!important;
    &--password {
      margin-bottom: 0px; }
    &--icon {
      position: absolute;
      top: 8px;
      left: 10px;
      font-size: 16px; }
    input {
      width: 100%;
      height: 32px;
      border: 1px solid #aaa;
      border-radius: 0;
      padding-left: 30px!important; } }
  &__googleLoginBtn {
    display: block;
    height: 32px;
    width: 180px;
    background: url(../images/google-sign-in/btn_google_signin_normal.png) center 100% no-repeat;
    outline: none;
    margin: 0 auto;
    &:hover {
      background-image: url(../images/google-sign-in/btn_google_signin_focus.png); }
    &:active {
      background-image: url(../images/google-sign-in/btn_google_signin_pressed.png); } }
  fieldset {
    border-top: 1px solid #ccc;
    border-left: none;
    border-bottom: none;
    border-right: none;
    margin: 0 auto;
    width: 88%; }
  legend {
    text-align: center;
    padding: 10px;
    color: #ccc; } }

.calenderIntegration {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: auto;
  min-width: auto;
  margin: 20px;
  background: #eee;
  .calenderIntegrationMessage {
    padding: 20px;
    font-size: 1.8em;
    color: #666;
    text-align: center;
    background: #fff;
    div {
      font-size: .8em; } } }

// https://simpro.atlassian.net/browse/MOB-1465
.mobile {
  padding: 2em;
  box-sizing: border-box;
  margin: 0 auto;
  min-width: auto;
  .validGroupedInput, input {
    max-width: 250px; }
  @media only screen and (min-width : 768px) and (max-width : 1024px) {
    width: 40%; }
  @include media(oversmallest) {
    width: 30%;
    background: $bodyBackground;
    #resetPwdForm {
      background: white;
      padding: 1em;
      margin-top: 10em; } } }


//sass for placeholder color
@mixin placeholder() {
  input::-webkit-input-placeholder {
    @content; }
  input:-moz-placeholder {
    @content; }
  input::-moz-placeholder {
    @content; }
  input:-ms-input-placeholder {
    @content; } }

@include placeholder() {
  color: #B1B1B1; }

// styling for the portal logins
#loginPageNonMarketing {
  background: #fff;
  .whiteBox {
    @include border_radius(10px);
    width: 500px;
    padding: 20px 0;
    margin: 10% auto auto auto;
    border: 1px solid #d9d9d9;
    position: relative; }
  #loginForm {
    width: 460px;
    margin: 0 auto; }
  .login {
    margin-bottom: 15px; }
  .license-info {
    p {
      font-size: .9em;
      color: #999; } }
  .errorLogin {
    background: lighten($red, 40%);
    padding: 10px;
    color: $red;
    font-size: 1em;
    a {
      color: #333;
      &:hover {
        color: #666; } } }
  .information {
    p {
      color: #999; } } }
.posMargin {
  margin: 10px 0; }

